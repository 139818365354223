import { ChangeDetectorRef, Component, OnInit,  ViewChild, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgIf, NgComponentOutlet } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { HeaderComponent } from './components/header/header.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NbcuLoaderComponent } from '@NBCUniversalOTS/tl-shared-storybook-ng';
import { darkModePreference$ } from '@NBCUniversalOTS/tl-shared-storybook-ng';
import { LoadingService } from './services/loading/loading.service';
import {MatSidenav, MatSidenavModule} from '@angular/material/sidenav';
import { DataService } from './services/data.service';
import { TabConfigComponent } from './components/title-maintenance/tab-config/tab-config.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterOutlet,
    HeaderComponent,
    SideMenuComponent,
    NbcuLoaderComponent,
    MatSidenavModule,
    TabConfigComponent
],
})
export class AppComponent implements OnInit {
  title = 'Release-Management-POC';
  isLoading: boolean;
  sso: string = localStorage.getItem('uid')!;
  @ViewChild('sidenav') sidenav: MatSidenav | undefined;
  dataService = inject(DataService);
  customComponent = null;
  customComponentInputs = {}
  constructor(
    private auth: AuthService,
    private change: ChangeDetectorRef,
    private loading: LoadingService,
  ) {
    this.isLoading = true;
    loading.isLoading = true;
    this.dataService.getConfigPanelVisibility().subscribe((isOpen: boolean) => {
      isOpen ? this.sidenav?.open() : this.sidenav?.close();
    });
  }

  ngOnInit(): void {
    darkModePreference$.next('light');
    const token = localStorage.getItem('access_token');
    if (token) {
      this.authorized();
    }

    this.loading._isLoading.subscribe((value) => {
      this.isLoading = value;
      this.change.markForCheck();
    });
  }

  /* When the app.component is initialized (happens only once)
  checks the validity of the token if it already exists (ex. from not logging out) */
  async authorized() {
    const valid = await this.auth.validateToken();
    if (valid) {
      this.isLoading = false;
      this.loading.isLoading = false;
    } else {
      this.auth.logout();
      this.auth.login();
    }
  }

  closeSideNav() {
    this.sidenav?.close();
  }
}
