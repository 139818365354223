<ng-container *ngIf="isLoading; else display">
  <div class="whole-page">
    <div class="center-container">
      <nbcu-loader></nbcu-loader>
    </div>
  </div>
</ng-container>
<ng-template #display>
  <mat-sidenav-container class="nav-container" (backdropClick)="closeSideNav()">
    <mat-sidenav class="side-nav" #sidenav (keydown.escape)="closeSideNav()"  position="end">
      <app-tab-config />
    </mat-sidenav>
    <mat-sidenav-content>
        <header>
          <div class="header-container">
            <app-header></app-header>
          </div>
        </header>
        <body>
          <div class="side-nav-container">
            <app-side-menu></app-side-menu>
          </div>
          <div class="content-container">
            <router-outlet></router-outlet>
          </div>
        </body>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>
