import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retryWhen, delay, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const maxRetries = 3;
    const scalingDelay = 1000;

    const retryStrategy = () => (attempts: Observable<any>) => {
      return attempts.pipe(
        mergeMap((error, retryCount) => {
          if (error.status === 400) {
            return throwError(() => error);
          } else if (retryCount >= maxRetries) {
            return throwError(() => error);
          } else {
            return of(error).pipe(delay(scalingDelay * Math.pow(2, retryCount)));
          }
        })
      );
    };

    const token = localStorage.getItem('api_token');
    const uid = localStorage.getItem('uid') || '';
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'X-Amz-Security-Token': uid,           
        },
        responseType: 'json',
        body: req.body,
      });
    }

    // Pass the cloned request with the updated header to the next handler and with error handling & retry pipe
    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          // Handle HTTP errors
          if (err.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error);
            // You might trigger a re-authentication flow or redirect the user here
          } else if (err.status === 401) {
            // Unauthorized error
            console.error('Unauthorized:', err.error);
            // You might trigger a re-authentication flow or redirect the user here
          } else {
            // Handle other HTTP error codes
            console.error(`Status ${err.status}, body was: `, err.message);
          }
        } else {
          // Handle non-HTTP errors
          console.error('An error occurred:', err);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error('Connection Error'));
      }),
      retryWhen(retryStrategy())
    );
  }
}