import { Component, inject, Input, model, OnChanges, SimpleChanges} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { Tab } from 'src/app/models/panel.model';
import {
  CdkDragDrop,
  CdkDrag,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

import {
  NbcuButtonComponent,
  NbcuContainedButtonComponent,
  NbcuCheckboxComponent
} from '@NBCUniversalOTS/tl-shared-storybook-ng';
import { DataService } from 'src/app/services/data.service';
import { MESSAGE, NOTIFICATION } from 'src/app/shared/constants/common';


const PAGE_NAME = 'Domestic Title Maintenance';
@Component({
  selector: 'app-tab-config',
  standalone: true,
  imports: [MatCardModule, MatCheckboxModule, FormsModule, CdkDropList, CdkDrag,CdkDragHandle,
    NbcuContainedButtonComponent,
    NbcuCheckboxComponent,
  ],
  templateUrl: './tab-config.component.html',
  styleUrl: './tab-config.component.scss'
})

export class TabConfigComponent {
  readonly checked = model(false);
  data: any = [];
  isPanelVisible = false;

  private apiService = inject(ApiService);
  private dataService = inject(DataService);

  constructor() {
    this.dataService.getUserPreferences().subscribe((data: any) => {
      this.data = data;
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      event.container.data.forEach((x,index)=>{
        x.sequenceNumber=index+1;
    });
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  updatePanelView() {
    const userSso = Number(localStorage.getItem('uid'));
    const userId= Number(localStorage.getItem('user_id'))
    const pageName = PAGE_NAME; // For now, this is static
    const body = {
      user_id: userId,
      result: this.data
    }
    const reqPayload = JSON.stringify(body);
    this.apiService.saveUserPreferences(userSso, pageName, reqPayload).then(
      () => {
        this.dataService.setUserPreferences(this.data);
        this.dataService.openSnackBar(MESSAGE.USER_PREF.SUCCESS, NOTIFICATION.TYPE.SUCCESS, NOTIFICATION.SUCCESS_CLASS);
        this.closeConfigPanel();
    });
  }

  closeConfigPanel() {
    this.dataService.setConfigPanelVisibility(false);
  }
}
