<div class="config-panel">
    <header>
        <span>Configure</span>
        <span class="material-icons md-18" (click)="closeConfigPanel()">close</span>
    </header>

    <div class="panel-content">
        <div  class="panel-list">
            @for (tab of data; track tab.tabId) {
                <div >
                    <div class="d-flex tab-drag-content">
                        <div class="tab-title">{{tab.tabTitle}}</div>
                    </div>
                    <div class="title-item header">
                        <span>
                            Content
                        </span>
                        <span>
                            Show
                        </span>
                        <span>
                            Expand
                        </span>
                    </div>
                    <div cdkDropList [cdkDropListData]="tab.panels" class="panel-list" (cdkDropListDropped)="drop($event)" >
                        @for (panel of tab.panels; track panel.id) {
                            <div class="title-item"  cdkDrag >
                                <div class="d-flex drag-content">
                                    <span class="material-icons md-18 drag-handle" cdkDragHandle>
                                        drag_handle
                                    </span>
                                    <span class="description-label">
                                        {{panel.description}}
                                    </span>
                                </div>
                                <div>
                                    <nbcu-checkbox
                                        class="show-chk"
                                        [checked]="panel.show"
                                        (change)="panel.show = !panel.show"
                                        ></nbcu-checkbox>
                                </div>
                                <div>
                                    <nbcu-checkbox
                                        class="show-chk"
                                        [checked]="panel.isOpen"
                                        (change)="panel.isOpen = !panel.isOpen"
                                        ></nbcu-checkbox>
                                </div>
                            </div>
                        }  
                    </div>
                </div>

            }
        </div>
    </div>
    <footer class="config-buttons">
        <nbcu-contained-button id='my-test' content="APPLY" tooltip="Save Config"  (buttonClick)="updatePanelView()"/>
    </footer>
</div>

