import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { TheatricalRelease } from '../models/theatrical-release.model';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  private userPreferences = new BehaviorSubject<any>(null);
  private imdbId = new BehaviorSubject<string>('');
  private isSideNavOpen = new BehaviorSubject(false);
  private snackbar = inject(MatSnackBar);
  private releaseDates = new BehaviorSubject<TheatricalRelease[]>([]);

  private posterInfo = new BehaviorSubject<any>({
    posterName: '',
    posterLanguage: '',
    imageSrc: '',
  });

  setConfigPanelVisibility(data: boolean) {
    this.isSideNavOpen.next(data);
  }

  getConfigPanelVisibility() {
    return this.isSideNavOpen.asObservable();
  }

  setUserPreferences(data: any) {
    this.userPreferences.next(data);
  }

  getUserPreferences() {
    return this.userPreferences.asObservable();
  }

  setIMDBId(data: string) {
    this.imdbId.next(data);
  }

  getIMDBId() {
    return this.imdbId.asObservable();
  }

  setReleaseDates(data: TheatricalRelease[]) {
    this.releaseDates.next(data);
  }

  getReleaseDates() {
    return this.releaseDates.asObservable();
  }

  setPosterInfo(data: { posterName: string, imageSrc: string, posterLanguage: string }) {
    this.posterInfo.next(data);
  }

  getPosterInfo() {
    return this.posterInfo.asObservable();
  }

  openSnackBar(message: string, action: string, className: string) {
    this.snackbar.open(message, action, {
      duration: 9000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: [className],
    });
  }
  
}