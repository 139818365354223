export const NOTIFICATION = {
    TYPE: {
        SUCCESS: 'success',
        ERROR: 'error'
    },
    SUCCESS_CLASS: 'success-snackbar',
    ERROR_CLASS: ''
}

export const MESSAGE = {
    USER_PREF: {
        SUCCESS: 'User preferences saved successfully!'
    }
}

export const CARD = {
    PRODUCTION_BUDGET: {
        NAME: 'budget',
        SOURCE_FIELD: 'production_budget_source'
    },
    THEATRICAL_RELEASE: {
        NAME: 'release',
        RELEASE_PATTERN_FIELD: 'release_pattern'
    },
    TITLE_TRACKING: {
        FORM_ERRORS: {
            END_DATE_LESSER: 'End Date cannot be less than Start Date.',
            OVERLAP: 'Overlapping history.Kindly adjust the other period, first.',
            START_DATE_BLANK: 'Start Date cannot be blank when there are additional tracking records with a valid Start Dates.',
            END_DATE_BLANK: 'End Date cannot be blank when there are additional tracking records with a valid End Dates.',
            NO_BLANK_DATE: 'Existing dates cannot be blank when adding new tracking record.'
        }
    },
    AWARD: {
        FORM_ERRORS: {
            NO_BLANK_FIELDS: 'Required fields cannot be blank when adding new Awards.',
            YEAR_REQUIRED: 'Year is required.',
            AWARD_CEREMONY_REQUIRED: 'Award ceremony is required.',
            AWARD_CATEGORY_REQUIRED: 'Award category is required.',
        }
    },
    AVAILABILITY_DATES: {
        FORM_ERRORS: {
            NO_BLANK_FIELDS: 'Required fields cannot be blank when adding new record.',
            MEDIA_TYPE_REQUIRED: 'Media Type is required.',
            PROVIDER_REQUIRED: 'Provider is required.',
            ENTER_NUMBERS:'Enter a valid number',
            OVERLAP: 'Overlapping history.Kindly adjust the other period, first.',
        }
    }
}

export const PRODUCTION_BUDGET_SOURCE = [
    'Gracenote',
    'The Numbers',
    'Variety',
    'Hollywood Reporter',
    'Deadline',
    'Studio System',
    'Box Office Mojo',
    'LA Times',
    'Other',
    'The Wrap',
    'Indiewire',
    'IMDB'
]